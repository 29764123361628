.rti--container {
  border-color: transparent;
  padding: 5px;
}
.rti--input {
  width: 100%;
  padding: 0.5rem;
  background-color: transparent;
  color: inherit;
}
.rti--tag {
  background: #1a202c;
}
.rti--tag span {
  color: #fff;
}
